<template>
  <b-card :title="`${$t('General.Add')} ${$t('Employee')}`">
    <validation-observer ref="simpleRules">
      <b-overlay
        :show="showOverLay"
        rounded="sm"
        :opacity="0.5"
      >
        <b-form ref="formName">
          <b-row>
            <b-col cols="12">
              <b-tabs>
                <b-tab active>
                  <template #title>
                    <feather-icon
                      icon="UserIcon"
                      size="22"
                    />
                    <span size="22">{{ $t('EmployeeInfo.Person Info') }}</span>
                  </template>
                  <b-row>
                    <b-col md="6">
                      <b-form-group
                        :label="$t('General.ArabicName')"
                        label-for="arabicName"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('General.ArabicName')"
                          rules="required"
                        >
                          <b-form-input
                            v-model="name_ar"
                            :state="errors.length > 0 ? false:null"
                            :placeholder="$t('General.ArabicName')"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        :label="$t('General.EnglishName')"
                        label-for="englishName"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('General.EnglishName')"
                          rules="required"
                        >
                          <b-form-input
                            v-model="name_en"
                            :state="errors.length > 0 ? false:null"
                            :placeholder="$t('General.EnglishName')"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- <b-col md="6">
                      <b-form-group
                        :label="$t('EmployeeInfo.Birthdate')"
                        label-for="birthDate"
                      >
                        <b-form-datepicker
                          id="datepicker-placeholder"
                          v-model="birth_date"
                          :placeholder="$t('EmployeeInfo.Birthdate')"
                          local="en"
                        />
                      </b-form-group>
                    </b-col> -->
                    <b-col md="6">
                      <b-form-group
                        :label="$t('EmployeeInfo.Birthdate')"
                        label-for="birthDate"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('EmployeeInfo.Birthdate')"
                          rules="required"
                        >
                          <flat-pickr
                            v-model="birth_date"
                            class="form-control"
                            :state="errors.length > 0 ? false:null"
                            :placeholder="$t('EmployeeInfo.Birthdate')"
                            local="en"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('Nationality')"
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('Nationality')"
                          label-for="nationality"
                          :state="errors.length > 0 ? false:null"
                        >
                          <v-select
                            v-model="nationality_id"
                            :state="nationality_id === null ? false : true"
                            :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                            :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                            :options="nationality_option"
                            :placeholder="$t('Nationality')"
                          />
                          <b-form-invalid-feedback
                            :state="errors.length > 0 ? false:null"
                          >
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        :label="$t('EmployeeInfo.Number Family')"
                        label-for="numberFamily"
                      >
                        <b-form-input
                          v-model.number="number_family"
                          :placeholder="$t('EmployeeInfo.Number Family')"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        :label="$t('General.Active')"
                        label-for="active"
                      >
                        <b-form-checkbox
                          v-model="active"
                          class="custom-control-primary"
                        >
                          {{ $t('General.Active') }}
                        </b-form-checkbox>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-tab>
                <b-tab>
                  <template #title>
                    <feather-icon
                      icon="BriefcaseIcon"
                      size="22"
                    />
                    <span size="22">{{ $t('EmployeeInfo.Work Info') }}</span>
                  </template>
                  <b-row>
                    <b-col md="6">
                      <validation-provider
                        #default="{ errors }"
                        :name="`${$t('Organization')} ${$t('General.Name')}`"
                        rules="required"
                      >
                        <b-form-group
                          :label="`${$t('Organization')} ${$t('General.Name')}`"
                          label-for="organizationName"
                          :state="errors.length > 0 ? false:null"
                        >
                          <v-select
                            v-model="organization_id"
                            :state="organization_id === null ? false : true"
                            :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                            :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                            :options="organization_option"
                            :placeholder="`${$t('Organization')} ${$t('General.Name')}`"
                          />
                          <b-form-invalid-feedback
                            :state="errors.length > 0 ? false:null"
                          >
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col md="6">
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('Branch')"
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('Branch')"
                          label-for="branch"
                          :state="errors.length > 0 ? false:null"
                        >
                          <v-select
                            v-model="branch_id"
                            :state="branch_id === null ? false : true"
                            :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                            :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                            :options="branch_option"
                            :placeholder="$t('Branch')"
                          />
                          <b-form-invalid-feedback
                            :state="errors.length > 0 ? false:null"
                          >
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        :label="`${$t('General.Phone')} ${$t('General.Work')}`"
                        label-for="phoneWork"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="`${$t('General.Phone')} ${$t('General.Work')}`"
                          rules="required"
                        >
                          <b-form-input
                            v-model="phone_work"
                            :state="errors.length > 0 ? false:null"
                            :placeholder="`${$t('General.Phone')} ${$t('General.Work')}`"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        :label="`${$t('General.Phone')} ${$t('General.Person')}`"
                        label-for="phonePerson"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="`${$t('General.Phone')} ${$t('General.Person')}`"
                          rules="required"
                        >
                          <b-form-input
                            v-model="phone_person"
                            :state="errors.length > 0 ? false:null"
                            :placeholder="`${$t('General.Phone')} ${$t('General.Person')}`"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        :label="$t('EmployeeInfo.Tel Ext')"
                        label-for="telExt"
                      >
                        <b-form-input
                          v-model.number="tel_ext"
                          :placeholder="$t('EmployeeInfo.Tel Ext')"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        :label="$t('EmployeeInfo.Job Number')"
                        label-for="jobNumber"
                      >
                        <b-form-input
                          v-model.number="job_number"
                          :placeholder="$t('EmployeeInfo.Job Number')"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- <b-col md="6">
                      <b-form-group
                        :label="$t('EmployeeInfo.Join date')"
                        label-for="joinDate"
                      >
                        <b-form-datepicker
                          id="datepicker-placeholder1"
                          v-model="join_date"
                          :placeholder="$t('EmployeeInfo.Join date')"
                          local="en"
                        />
                      </b-form-group>
                    </b-col> -->
                    <b-col md="6">
                      <b-form-group
                        :label="$t('EmployeeInfo.Join Date')"
                        label-for="joinDate"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('EmployeeInfo.Join Date')"
                          rules="required"
                        >
                          <flat-pickr
                            v-model="join_date"
                            class="form-control"
                            :state="errors.length > 0 ? false:null"
                            :placeholder="$t('EmployeeInfo.Join Date')"
                            local="en"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('Contract Type')"
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('Contract Type')"
                          label-for="contractType"
                          :state="errors.length > 0 ? false:null"
                        >
                          <v-select
                            v-model="contract_type_id"
                            :state="contract_type_id === null ? false : true"
                            :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                            :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                            :options="contract_type_option"
                            :placeholder="$t('Contract Type')"
                          />
                          <b-form-invalid-feedback
                            :state="errors.length > 0 ? false:null"
                          >
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-tab>
                <b-tab>
                  <template #title>
                    <feather-icon
                      icon="LockIcon"
                      size="22"
                    />
                    <span size="22">{{ $t('Account') }}</span>
                  </template>
                  <b-row>
                    <b-col md="6">
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('Bank')"
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('Bank')"
                          label-for="bank"
                          :state="errors.length > 0 ? false:null"
                        >
                          <v-select
                            v-model="bank_id"
                            :state="bank_id === null ? false : true"
                            :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                            :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                            :options="bank_option"
                            :placeholder="$t('Bank')"
                          />
                          <b-form-invalid-feedback
                            :state="errors.length > 0 ? false:null"
                          >
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col md="6">
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('Bank Branch')"
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('Bank Branch')"
                          label-for="bankBranch"
                          :state="errors.length > 0 ? false:null"
                        >
                          <v-select
                            v-model="bank_branch_id"
                            :state="bank_branch_id === null ? false : true"
                            :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                            :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                            :options="bank_branch_option"
                            :placeholder="$t('Bank Branch')"
                          />
                          <b-form-invalid-feedback
                            :state="errors.length > 0 ? false:null"
                          >
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        :label="`${$t('Bank')} ${$t('General.Card')}`"
                        label-for="bankCard"
                      >
                        <b-form-input
                          v-model.number="bank_card"
                          :placeholder="`${$t('Bank')} ${$t('General.Card')}`"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        :label="$t('EmployeeInfo.IBAN')"
                        label-for="iBAN"
                      >
                        <b-form-input
                          v-model.number="IBAN_account_number"
                          :placeholder="$t('EmployeeInfo.IBAN')"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('Account Tree')"
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('Account Tree')"
                          label-for="accountTree"
                          :state="errors.length > 0 ? false:null"
                        >
                          <v-select
                            v-model="account_tree_id"
                            :state="account_tree_id === null ? false : true"
                            :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                            :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                            :options="account_tree_option"
                            :placeholder="$t('Account Tree')"
                          />
                          <b-form-invalid-feedback
                            :state="errors.length > 0 ? false:null"
                          >
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col md="12">
                      <h4 class="p-1">
                        {{ $t('EmployeeInfo.Register Account Login') }}
                      </h4>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        :label="$t('EmployeeInfo.User Name')"
                        label-for="userName"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('EmployeeInfo.User Name')"
                          rules="required"
                        >
                          <b-form-input
                            v-model="user_name"
                            :state="errors.length > 0 ? false:null"
                            :placeholder="$t('EmployeeInfo.User Name')"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        :label="$t('General.Email')"
                        label-for="email"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('General.Email')"
                          rules="required|email"
                        >
                          <b-form-input
                            v-model="email_emp"
                            :state="errors.length > 0 ? false:null"
                            :placeholder="$t('General.Email')"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        :label="$t('General.Password')"
                        label-for="password"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('General.Password')"
                          rules="required"
                        >
                          <b-form-input
                            v-model="password"
                            :state="errors.length > 0 ? false:null"
                            :placeholder="$t('General.Password')"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        :label="`${$t('General.Password')} ${$t('General.Confirmation')}`"
                        label-for="passwordConfirmation"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="`${$t('General.Password')} ${$t('General.Confirmation')}`"
                          rules="required"
                        >
                          <b-form-input
                            v-model="password_confirmation"
                            :state="errors.length > 0 ? false:null"
                            :placeholder="`${$t('General.Password')} ${$t('General.Confirmation')}`"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-tab>
                <b-tab>
                  <template #title>
                    <feather-icon
                      icon="ClipboardIcon"
                      size="22"
                    />
                    <span size="22">{{ $t('EmployeeInfo.Other Info') }}</span>
                  </template>
                  <b-row>
                    <b-col md="6">
                      <validation-provider
                        #default="{ errors }"
                        :name="`${$t('Country')} ${$t('General.Name')}`"
                        rules="required"
                      >
                        <b-form-group
                          :label="`${$t('Country')} ${$t('General.Name')}`"
                          label-for="countryName"
                          :state="errors.length > 0 ? false:null"
                        >
                          <v-select
                            v-model="country_id"
                            :state="country_id === null ? false : true"
                            :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                            :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                            :options="country_option"
                            :placeholder="`${$t('Country')} ${$t('General.Name')}`"
                          />
                          <b-form-invalid-feedback
                            :state="errors.length > 0 ? false:null"
                          >
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col md="6">
                      <validation-provider
                        #default="{ errors }"
                        :name="`${$t('City')} ${$t('General.Name')}`"
                        rules="required"
                      >
                        <b-form-group
                          :label="`${$t('City')} ${$t('General.Name')}`"
                          label-for="cityName"
                          :state="errors.length > 0 ? false:null"
                        >
                          <v-select
                            v-model="city_id"
                            :state="city_id === null ? false : true"
                            :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                            :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                            :options="city_option"
                            :placeholder="`${$t('City')} ${$t('General.Name')}`"
                          />
                          <b-form-invalid-feedback
                            :state="errors.length > 0 ? false:null"
                          >
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col md="6">
                      <validation-provider
                        #default="{ errors }"
                        :name="`${$t('Area')} ${$t('General.Name')}`"
                        rules="required"
                      >
                        <b-form-group
                          :label="`${$t('Area')} ${$t('General.Name')}`"
                          label-for="areaName"
                          :state="errors.length > 0 ? false:null"
                        >
                          <v-select
                            v-model="area_id"
                            :state="area_id === null ? false : true"
                            :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                            :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                            :options="area_option"
                            :placeholder="`${$t('Area')} ${$t('General.Name')}`"
                          />
                          <b-form-invalid-feedback
                            :state="errors.length > 0 ? false:null"
                          >
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col md="6">
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('Language')"
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('Language')"
                          label-for="languageName"
                          :state="errors.length > 0 ? false:null"
                        >
                          <v-select
                            v-model="language_id"
                            :state="language_id === null ? false : true"
                            :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                            :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                            :options="language_option"
                            :placeholder="$t('Language')"
                          />
                          <b-form-invalid-feedback
                            :state="errors.length > 0 ? false:null"
                          >
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col md="12">
                      <b-form-group
                        :label="$t('General.Address')"
                        label-for="address"
                      >
                        <b-form-textarea
                          v-model="address"
                          :placeholder="$t('General.Address')"
                          rows="3"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('Gender')"
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('Gender')"
                          label-for="genderName"
                          :state="errors.length > 0 ? false:null"
                        >
                          <v-select
                            v-model="gender_id"
                            :state="gender_id === null ? false : true"
                            :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                            :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                            :options="gender_option"
                            :placeholder="$t('Gender')"
                          />
                          <b-form-invalid-feedback
                            :state="errors.length > 0 ? false:null"
                          >
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col md="6">
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('Social Status')"
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('Social Status')"
                          label-for="socialStatus"
                          :state="errors.length > 0 ? false:null"
                        >
                          <v-select
                            v-model="social_status_id"
                            :state="social_status_id === null ? false : true"
                            :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                            :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                            :options="social_status_option"
                            :placeholder="$t('Social Status')"
                          />
                          <b-form-invalid-feedback
                            :state="errors.length > 0 ? false:null"
                          >
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col md="6">
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('Calendar')"
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('Calendar')"
                          label-for="calendar"
                          :state="errors.length > 0 ? false:null"
                        >
                          <v-select
                            v-model="calendar_id"
                            :state="calendar_id === null ? false : true"
                            :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                            :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                            :options="calendar_option"
                            :placeholder="$t('Calendar')"
                          />
                          <b-form-invalid-feedback
                            :state="errors.length > 0 ? false:null"
                          >
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col md="6">
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('Group')"
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('Group')"
                          label-for="group"
                          :state="errors.length > 0 ? false:null"
                        >
                          <v-select
                            v-model="group_id"
                            :state="group_id === null ? false : true"
                            :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                            :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                            :options="group_option"
                            :placeholder="$t('Group')"
                          />
                          <b-form-invalid-feedback
                            :state="errors.length > 0 ? false:null"
                          >
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-tab>
                <!-- <b-tab>
                  <template #title>
                    <feather-icon
                      icon="PaperclipIcon"
                      size="22"
                    />
                    <span size="22">Attachment</span>
                  </template>
                  <b-row>
                    <b-col md="1" />
                    <b-col
                      md="6"
                      xl="4"
                    >
                      <b-card
                        :img-src="require('@/assets/images/slider/06.jpg')"
                        img-top
                        img-alt="card img"
                        title="Profile"
                        class="mb-3"
                      >
                        <b-form-file
                          v-model="profile"
                          placeholder="Choose a file or drop it here..."
                          drop-placeholder="Drop file here..."
                        />
                      </b-card>
                    </b-col>
                    <b-col md="2" />
                    <b-col
                      md="6"
                      xl="4"
                    >
                      <b-card
                        :img-src="require('@/assets/images/slider/06.jpg')"
                        img-top
                        img-alt="card img"
                        title="Signature"
                        class="mb-3"
                      >
                        <b-form-file
                          v-model="signature"
                          placeholder="Choose a file or drop it here..."
                          drop-placeholder="Drop file here..."
                        />
                      </b-card>
                    </b-col>
                    <b-col md="1" />
                  </b-row>
                </b-tab> -->
              </b-tabs>
            </b-col>
            <b-col cols="6">
              <!-- alert -->
              <b-alert
                v-height-fade.appear
                variant="danger"
                :show="showError"
                class="text-left p-1"
              >
                <ul class="list-style-icons mb-0">
                  <li><feather-icon icon="ArrowRightIcon" />{{ apiErrors }}</li>
                </ul>
              </b-alert>
            </b-col>
            <b-col cols="3" />
            <b-col cols="3" />
            <b-col cols="3" />
            <b-col cols="6 text-center p-1">
              <b-button
                variant="primary"
                type="submit"
                block
                @click.prevent="validationForm"
              >
                {{ $t('General.Save') }}
              </b-button>
            </b-col>
            <b-col cols="3" />
          </b-row>
        </b-form>
      </b-overlay>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCard, BOverlay, BAlert, BTabs, BTab, BFormCheckbox, BFormTextarea,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import employee from '@/service/administration/employee'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { heightFade } from '@core/directives/animations'
import vSelect from 'vue-select'
import area from '@/service/administration/area'
import bank from '@/service/administration/bank'
import flatPickr from 'vue-flatpickr-component'
import bankBranch from '@/service/administration/bank-branch'
import calendar from '@/service/administration/calendar'
import city from '@/service/administration/city'
import country from '@/service/administration/country'
import gender from '@/service/administration/gender'
import group from '@/service/administration/group'
import nationality from '@/service/administration/nationality'
import socialStatus from '@/service/administration/social-status'
import language from '@/service/administration/language'
import accountTree from '@/service/accounting/account-tree'
import organization from '@/service/administration/organization'
import branch from '@/service/administration/branch'
import contractType from '@/service/administration/contract-type'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BOverlay,
    BAlert,
    BTabs,
    BTab,
    BFormCheckbox,
    vSelect,
    BFormTextarea,
    flatPickr,
    // BFormDatepicker,
  },
  directives: {
    'height-fade': heightFade,
  },
  data() {
    return {
      name_ar: '',
      name_en: '',
      required,
      email,
      showError: false,
      showOverLay: false,
      apiErrors: [],
      nationality_id: '',
      nationality_option: [],
      active: true,
      number_family: '',
      phone_work: '',
      phone_person: '',
      tel_ext: '',
      job_number: '',
      join_date: '',
      birth_date: '',
      branch_id: '',
      branch_option: [],
      organization_id: '',
      organization_option: [],
      contract_type_id: '',
      contract_type_option: [],
      bank_id: '',
      bank_option: [],
      bank_branch_id: '',
      account_tree_option: [],
      account_tree_id: '',
      bank_branch_option: [],
      IBAN_account_number: '',
      bank_card: '',
      email_emp: '',
      user_name: '',
      password: '',
      password_confirmation: '',
      country_id: '',
      country_option: [],
      city_id: '',
      city_option: [],
      area_id: '',
      area_option: [],
      language_id: '',
      language_option: [],
      social_status_id: '',
      social_status_option: [],
      gender_id: '',
      gender_option: [],
      calendar_id: '',
      calendar_option: [],
      address: '',
      profile: '',
      signature: '',
      group_option: [],
      group_id: '',
      locale: this.$i18n.locale,
    }
  },
  async created() {
    this.getApiSelect()
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.postApi()
        }
        this.switchLoc()
      })
    },
    async postApi() {
      this.showOverLay = true
      const user = JSON.parse(localStorage.getItem('userData'))
      await employee.postAxios({
        name_ar: this.name_ar,
        name_en: this.name_en,
        add_user_id: user.id,
        nationality_id: this.nationality_id.id,
        active: this.active,
        number_family: this.number_family,
        phone_work: this.phone_work,
        phone_person: this.phone_person,
        tel_ext: this.tel_ext,
        job_number: this.job_number,
        join_date: this.join_date,
        birth_date: this.birth_date,
        branch_id: this.branch_id.id,
        contract_type_id: this.contract_type_id.id,
        bank_branch_id: this.bank_branch_id.id,
        account_tree_id: this.account_tree_id.id,
        IBAN_account_number: this.IBAN_account_number,
        bank_card: this.bank_card,
        email: this.email_emp,
        name: this.user_name,
        password: this.password,
        password_confirmation: this.password_confirmation,
        area_id: this.area_id.id,
        language_id: this.language_id.id,
        social_status_id: this.social_status_id.id,
        gender_id: this.gender_id.id,
        calendar_id: this.calendar_id.id,
        address: this.address,
        profile: this.profile,
        signature: this.signature,
        group_id: this.group_id.id,
        gender_degreer_id: 1,
      }).then(() => {
        this.$refs.formName.reset()
        this.showError = false
        this.showToast(this.$t('General.Successfully'), 'CheckCircleIcon', this.$t('General.SavedSuccessfully'), 'success')
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
        this.showToast(this.$t('General.Error'), 'XCircleIcon', this.$t('General.SavedError'), 'danger')
      }).finally(() => {
        this.clearForm()
        this.showOverLay = false
      })
    },
    clearForm() {
      this.name_ar = ''
      this.name_en = ''
      this.nationality_id = ''
      this.active = true
      this.number_family = ''
      this.phone_work = ''
      this.phone_person = ''
      this.tel_ext = ''
      this.job_number = ''
      this.join_date = ''
      this.birth_date = ''
      this.organization_id = ''
      this.branch_id = ''
      this.contract_type_id = ''
      this.bank_id = ''
      this.bank_branch_id = ''
      this.account_tree_id = ''
      this.IBAN_account_number = ''
      this.bank_card = ''
      this.email_emp = ''
      this.user_name = ''
      this.password = ''
      this.password_confirmation = ''
      this.country_id = ''
      this.city_id = ''
      this.area_id = ''
      this.language_id = ''
      this.social_status_id = ''
      this.gender_id = ''
      this.calendar_id = ''
      this.address = ''
      this.profile = ''
      this.signature = ''
      this.group_id = ''
      this.$refs.simpleRules.reset()
    },
    showToast(title, icon, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      })
    },
    switchLoc() {
      this.locale = this.$i18n.locale
      localize(this.locale)
    },
    async getApiSelect() {
      this.showOverLay = true
      await area.getAxios().then(response => {
        this.area_option = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      await bank.getAxios().then(response => {
        this.bank_option = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      await bankBranch.getAxios().then(response => {
        this.bank_branch_option = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      await accountTree.getAxios().then(response => {
        this.account_tree_option = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      await branch.getAxios().then(response => {
        this.branch_option = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      await calendar.getAxios().then(response => {
        this.calendar_option = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      await city.getAxios().then(response => {
        this.city_option = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      await country.getAxios().then(response => {
        this.country_option = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      await contractType.getAxios().then(response => {
        this.contract_type_option = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      await gender.getAxios().then(response => {
        this.gender_option = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      await group.getAxios().then(response => {
        this.group_option = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      await language.getAxios().then(response => {
        this.language_option = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      await nationality.getAxios().then(response => {
        this.nationality_option = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      await organization.getAxios().then(response => {
        this.organization_option = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      await socialStatus.getAxios().then(response => {
        this.social_status_option = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      }).finally(() => { this.showOverLay = false })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
